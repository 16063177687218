.contact-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.contact-header {
    background-color: #007bff;
    color: white;
    font-size: 1.5em;
    text-align: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.contact-form .form-group label {
    font-weight: bold;
}

.map-container {
    margin-top: 20px;
    height: 85%;
    width: 95%;
    border-radius: 8px;
    overflow: hidden;
}

.contact-footer {
    text-align: center;
    background-color: #f8f9fa;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.custom-color {

    background-color: white;
    color: black;
}

@media screen and (max-width: 768px) {
    .map-container {
        margin-top: 20px;
        height: 300px;
        width: 95%;
        border-radius: 8px;
        overflow: hidden;
    }

}