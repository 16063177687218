.error404-container {
  font-family: Arial, sans-serif;
}

.custom-container{
  margin-top: 10%;
}

.error-title {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 1rem;
}

.error-description {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

.error-button {
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.25rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .error-title {
    font-size: 2rem;
  }
}
