.btn {
  background-color: #333 !important;
  color: white !important;
  border: none !important;
}

.btn:hover,
.btn:focus {
  background-color: #555 !important;
  color: white !important;
}