.centered-modal .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 1);
  }
  